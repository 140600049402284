<script>
import VideoCategoryNewView from './VideoCategoryNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'VideoCategoryEditView',
  extends: VideoCategoryNewView,
  methods: {
    getVideoCategory () {
      this.$store.dispatch('videoCategory/fetchOne', this.$route.params.id)
        .then(() => {
          this.videoCategory = this.$store.getters['videoCategory/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      if (this.videoCategory.imageInfo) {
        this.videoCategory.image = this.videoCategory.imageInfo.id
      }
      this.$store.dispatch('videoCategory/update', this.videoCategory)
        .then(() => {
          this.videoCategory = this.$store.getters['videoCategory/detail']
          if (this.$store.getters['videoCategory/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['videoCategory/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getVideoCategory()
  }
}
</script>
