<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete
                v-if="isDeleteButtonVisible"
                @deleteRecord="deleteRecord"
              />
              <app-button-close route-name="videoCategory_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <app-checkbox
                  v-model="videoCategory.enabled"
                  id="enabled"
                  :label="$t('videoCategory.enabled')"
                ></app-checkbox>
                <app-input
                  v-model.trim="videoCategory.title"
                  @blur="$v.videoCategory.title.$touch()"
                  :error="$v.videoCategory.title.$error"
                  id="videoCategory_title"
                  :label="$t('videoCategory.title')"
                ></app-input>
                <app-input
                  disabled
                  v-model="videoCategory.slug"
                  id="videoCategory_slug"
                  :label="$t('videoCategory.slug')"
                ></app-input>
                <div class="form-group">
                  <label>{{ $t('videoCategory.image') }}</label><br>
                  <app-media-editable
                    v-if="videoCategory.imageInfo"
                    :media="videoCategory.imageInfo"
                    @set-media="setImage"
                    @remove-media="removeImage"
                    :width="300"
                    :height="168"
                  ></app-media-editable>
                  <app-media-select-button
                    @set-media="setImage"
                    :selected-dam-media="videoCategory.imageInfo"
                    show-drop-zone
                    data-test="videoCategory_btn_select_image_from_dam"
                  ></app-media-select-button>
                  <app-media-upload-button
                    @set-media="setImage"
                    data-test="videoCategory_btn_select_image_from_pc"
                  ></app-media-upload-button>
                  <app-media-url-button
                    @set-media="setImage"
                    data-test="videoCategory_btn_select_image_from_url"
                  ></app-media-url-button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import Checkbox from '../../components/form/Checkbox'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import VideoCategoryModel from '../../model/VideoCategory'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import MediaSelectButton from '../../components/shared/MediaSelectButton'
import MediaUploadButton from '../../components/shared/MediaUploadButton'
import MediaUrlButton from '../../components/shared/MediaUrlButton'
import MediaEditable from '../../components/shared/MediaEditable'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'VideoCategoryNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      videoCategory: this._.cloneDeep(VideoCategoryModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.VIDEO_PLUS_TV_CATEGORY_PERMISSIONS
    }
  },
  computed: {
    isDeleteButtonVisible () {
      return this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    }
  },
  validations: {
    videoCategory: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appCheckbox: Checkbox,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaUrlButton: MediaUrlButton,
    appMediaEditable: MediaEditable
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        if (this.videoCategory.imageInfo) {
          this.videoCategory.image = this.videoCategory.imageInfo.id
        }
        this.$store.dispatch('videoCategory/create', this.videoCategory)
          .then(() => {
            if (this.$store.getters['videoCategory/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.videoCategory = this.$store.getters['videoCategory/detail']
              this.$router.push('/videoCategory/' + this.videoCategory.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['videoCategory/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteRecord () {
      this.$store.dispatch('videoCategory/deleteRecord', this.videoCategory)
        .then(() => {
          if (this.$store.getters['videoCategory/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/videoCategory')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['videoCategory/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setImage (medias) {
      console.log(medias)
      this.videoCategory.imageInfo = medias[0]
    },
    removeImage () {
      this.videoCategory.image = null
    }
  }
}
</script>
